<template>
  <v-container>
    <div class="product-details">
      <h1>Machine Status</h1>

      <v-row>
        <v-col cols="4">
          <!-- <v-combobox
            label="Location"
            solo
            dense
            :items="locationItems"
            item-value="id"
            item-text="name"
            v-model="selectedLocation"
          ></v-combobox> -->
        </v-col>
      </v-row>
    </div>

    <div class="list-container" v-if="displayMode === 2">
      <v-data-table
        :headers="headers"
        :items="machineList"
        :search="search"
        sort-by="transactionTime"
        sort-desc=true
        disable-pagination
        hide-default-footer
        fixed-header
        :expanded.sync="expanded"
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        item-key="serialNumber"
        show-expand
        height=500
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Machine Status</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-combobox
              label="Location"
              solo
              dense
              hide-details=true
              item-value="id"
              item-text="name"
              :items="locationItems"
              v-model="selectedLocation"
            ></v-combobox>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <!-- <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-btn
              color="info"
              dark
              class="mb-2"
              @click="refresh"
            >
              Refresh
            </v-btn> -->
          </v-toolbar>
        </template>
        <template v-slot:item.ipAddress="{ item }">
          <v-icon
            color="green"
            v-if="item.isConnected"
          >
            mdi-check-network
          </v-icon>
          <v-icon
            v-else color="red"
          >mdi-close-network</v-icon>
          {{ item.ipAddress }}
        </template>
        <template v-slot:item.sequenceNumber="{ item }">
          <v-icon>mdi-counter</v-icon>
          {{ item.sequenceNumber }}
        </template>
        <template v-slot:item.transactionTime="{ item }">
          {{ formatDate(item.transactionTime, 'D MMM, YY \xB7 HH:mm:ss') }}
        </template>
        <template v-slot:item.transactionWeight="{ item }">
          <v-list-item class="px-0">
            <v-list-item-icon class="px-0 mx-0 mr-2">
              <v-icon>mdi-package-variant-closed</v-icon>
            </v-list-item-icon>
            <v-list-item-subtitle class="">
              {{ formatWeight(item.transactionWeight) }}
            </v-list-item-subtitle>
          </v-list-item>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <!-- <v-icon>mdi-qrcode</v-icon>
            <span class="text-wrap ml-2">
              <a v-bind:href="'/#/product_details2?s=' + item.qrCodeData"
                target="_blank">{{ item.qrCodeData }}</a>
            </span> -->
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-qrcode</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-wrap">
                <a v-bind:href="'/#/product_details2?s=' + item.qrCodeData" target="_blank">
                  {{ item.qrCodeData }}
                </a>
              </v-list-item-subtitle>
            </v-list-item>
            <v-card
              class="mb-2"
              max-width="300"
              tile
            >
              <v-list class="transparent" dense>
                <v-subheader>ADDITIONAL DETAILS</v-subheader>
                <v-list-item>
                  <v-list-item-title>Checker No.</v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ item.checkerNumber }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Packer No.</v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ item.packerNumber }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>PO Number</v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ item.productionOfficerNumber }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Machine Number</v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{ item.machineNumber }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </div>

    <div class="grid-container" v-if="displayMode === 1">
      <v-row align="left">
        <v-col
          v-for="machine in machineList"
          :key="machine.serialNumber"
        >
          <v-card
            class="mx-auto"
            max-width="400"
            min-width="300"
          >
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title
                  class="text-h5"
                >
                  Machine · {{ machine.serialNumber }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        class="caption"
                        v-bind="attrs"
                        v-on="on"
                      >
                        (IP &#45; {{ machine.ipAddress ?
                         machine.ipAddress : 'n/a' }})
                      </span>
                      <v-icon
                        color="green"
                        v-if="machine.isConnected"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-check-network
                      </v-icon>
                      <v-icon
                        v-else color="red"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-close-network</v-icon>
                    </template>
                    <span>
                      IP &#45; {{ machine.ipAddress }}
                    </span>
                    <!-- <span>IP &#45; 192.168.8.192</span> -->
                  </v-tooltip>
                </v-list-item-title>
                <!-- <v-list-item-subtitle>Mon, 12:30 PM</v-list-item-subtitle> -->
                <v-list-item-subtitle>
                  {{ machine.transactionTime }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-row align="center">
                <v-col
                  class="text-h2"
                  cols="6"
                >
                  {{ machine.sequenceNumber }}
                  <!-- 23&deg;C -->
                </v-col>
                <!-- <v-col cols="6">
                  <v-img
                    src="https://cdn.vuetifyjs.com/images/cards/sun.png"
                    alt="Sunny image"
                    width="92"
                  ></v-img>
                </v-col> -->
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-icon class="px-0 mx-0 mr-2">
                      <v-icon>mdi-package-variant-closed</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle class="">
                      {{ formatWeight(machine.transactionWeight) }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-qrcode</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-wrap">
                <a v-bind:href="'/#/product_details2?s=' + machine.qrCodeData" target="_blank">
                  {{ machine.qrCodeData }}
                </a>
              </v-list-item-subtitle>
            </v-list-item>

            <!-- <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-cloud-download</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>48%</v-list-item-subtitle>
            </v-list-item> -->

            <!-- <v-slider
              v-model="time"
              :max="6"
              :tick-labels="labels"
              class="mx-4"
              ticks
            ></v-slider> -->

            <v-list class="transparent">
              <v-list-item
                v-for="item in machine.qrCodeDataFields"
                :key="item.key"
              >
                <v-list-item-title>{{ item.field }}</v-list-item-title>

                <!-- <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon> -->

                <v-list-item-subtitle class="text-right">
                  {{ item.data }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>

            <!-- <v-divider></v-divider>

            <v-card-actions>
              <v-btn text>
                View Transactions
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>

    </div>

  </v-container>
</template>

<script>
import machineApi from '@/api/machine'

const moment = require('moment-timezone')
const _ = require('lodash')
const { DeepstreamClient } = require('@deepstream/client')
// const machineList2 = require('../store/machine-list.json')

const machineListCache = []
const wsUrl = process.env.VUE_APP_WS_URL
// const ds = new DeepstreamClient('ws://localhost:6020')
// const ds = new DeepstreamClient('ws://192.168.6.105:6020')
// 'ws://prdgc.venusohs.com:6020'
const ds = new DeepstreamClient(wsUrl)

ds.on('error', (error, event, topic) => {
  console.log(error, event, topic)
})

export default {
  data: () => ({
    reveal: false,
    reveal2: false,
    snackbar: false,
    snackbarText: null,
    alertType: 'success',
    alertMsg: 'Genuine Product',
    showProductDetails: false,
    showProductContainer: false,
    displayMode: 2, /// 1 = grid; 2 = list
    search: null,
    productDetails: [],
    locationItems: [
      { id: 1, name: 'Taloja T-15' },
      { id: 2, name: 'Taloja L-76' },
      { id: 3, name: 'Taloja L-17' },
      { id: 4, name: 'Pawne' },
      { id: 5, name: 'Rabale' },
    ],
    // selectedLocation: { id: 4, name: 'Pawne' },
    selectedLocation: null,
    qrCodeDataFields: [
      { key: 'batchNumber', field: 'Batch Number', data: '' },
      { key: 'checkerNumber', field: 'Checker Number', data: '' },
      { key: 'packerNumber', field: 'Packer Number', data: '' },
      { key: 'productionOfficerNumber', field: 'PO Number', data: '' },
      { key: 'machineNumber', field: 'Machine Number', data: '' },
    ],
    headers: [
      { text: 'Serial No.', align: 'start', value: 'serialNumber' },
      { text: 'IP Address', align: 'start', value: 'ipAddress' },
      { text: 'Sequence No.', align: 'start', value: 'sequenceNumber' },
      { text: 'Machine No.', align: 'start', value: 'machineNumber' },
      { text: 'Weight', align: 'start', value: 'transactionWeight' },
      {
        text: 'Scan Time',
        align: 'start',
        value: 'transactionTime',
        sort: (a, b) => {
          let v = 10000
          if (a && b) {
            v = (a.getTime() - b.getTime())
          }

          return v
        },
      },
      { text: 'Item Code', align: 'start', value: 'itemCodeNumber' },
      { text: 'Batch No.', align: 'start', value: 'batchNumber' },
      { text: 'Checker No.', align: ' d-none', value: 'checkerNumber' },
      { text: 'Packer No.', align: ' d-none', value: 'packerNumber' },
      { text: 'PO Number', align: ' d-none', value: 'productionOfficerNumber' },
      { text: 'QRC', align: ' d-none', value: 'qrCodeData' },
    ],
    // sort: (a, b) => {
    //   console.log(a)
    //   console.log(b)
    //   return (a.getTime() - b.getTime())
    // },
    machineList: [],
    expanded: [],
    dsStatusRecords: [],
    dsSequenceRecords: [],
  }),
  computed: {
  },
  watch: {
    selectedLocation() {
      const id = parseInt(this.$route.query.loc_id, 0)
      if (id !== this.selectedLocation.id) {
        this.$router.push({ path: 'machine', query: { loc_id: this.selectedLocation.id } })
      }

      console.log('about to reload')
      this.loadMachineList(() => {
        console.log('aaa')
        this.enableSubscription()
      })
    },
  },
  created() {
    console.log('created')
    const locId = this.$route.query.loc_id
    if (locId) {
      console.log(locId)
      const item = _.find(this.locationItems, { id: parseInt(locId, 0) })
      console.log(item)
      if (item) {
        this.selectedLocation = { ...item }
      } else {
        this.selectedLocation = { ...this.locationItems[0] }
      }
    }

    ds.login({}, (success) => {
      console.log('deepstream ws callback')

      this.loadMachineList(() => {
        if (success) {
          console.log('deepstream ws login successful')

          // this.machineList[0].sequenceNumber = 129222
          // this.machineList[0].qrCodeData = 'asdlsadj_q2232_23232'
          // this.machineList[0].transactionWeight = 241.34223

          this.enableSubscription()
        }
      })
    })
    // const record2 = ds.record.getRecord('sequence/machine_20308959')
    // record2.whenReady(() => {
    //   record2.subscribe((data) => {
    //     this.updateHandler2(data)
    //   }, true)
    //   console.log(`Subscription enable for '${record.name}'`)
    // })

    // const record3 = ds.record.getRecord('sequence/machine_20311871')
    // record3.whenReady(() => {
    //   record3.subscribe((data) => {
    //     this.updateHandler3(data)
    //   }, true)
    //   console.log(`Subscription enable for '${record.name}'`)
    // })

    // const record4 = ds.record.getRecord('sequence/machine_20311871')
    // record4.whenReady(() => {
    //   record4.subscribe((data) => {
    //     this.updateHandler4(data)
    //   }, true)
    //   console.log(`Subscription enable for '${record.name}'`)
    // })
    // this.fetchProductInfo(s)
  },
  methods: {
    // formatWeight(weight) {
    //   let unit = 'g'
    //   let w = 0

    //   if (weight > 999.99) {
    //     w = weight / 1000
    //     unit = 'kg'
    //   } else {
    //     w = weight
    //   }

    //   if (w > 0) {
    //     w = Math.round(w * 1000) / 1000
    //   }

    //   return `${w} ${unit}`
    // },
    c1(item, s) {
      console.log(item)
      console.log(s)
    },
    refresh() {
    },

    updateHandler(data) {
      console.log(data)
      const machineRec = _.find(this.machineList, { serialNumber: data.serialNumber })
      if (machineRec) {
        machineRec.serialNumber = data.serialNumber
        machineRec.itemCodeNumber = data.itemCodeNumber
        machineRec.sequenceNumber = data.sequenceNumber
        machineRec.qrCodeData = data.qrCodeData
        machineRec.transactionTime = moment.tz(data.transactionTime, 'Asia/Kolkata').toDate()
        /*
        machineRec.transactionTime =
        moment.tz(data.transactionTime, 'Asia/Kolkata')
        .format('ddd D MMM, YYYY \xB7 hh:mm:ss A')
        */
        machineRec.transactionTime2 = moment.tz(data.transactionTime, 'Asia/Kolkata').format('D MMM, YY \xB7 HH:mm:ss')
        machineRec.transactionWeight = data.weight

        console.log(`${machineRec.serialNumber} - ${data.transactionTime}`)

        if (data.qrCodeDataFields) {
          _.each(data.qrCodeDataFields, (val, key1) => {
            /// For list view
            _.set(machineRec, key1, val)

            let field = _.find(machineRec.qrCodeDataFields, { key: key1 })
            if (!field) {
              field = _.clone(_.find(this.qrCodeDataFields, { key: key1 }))
              if (field) {
                machineRec.qrCodeDataFields.push(field)

                if (key1 === 'checkerNumber' && machineRec.isCartonPackingMachine) {
                  field.field = 'Voucher Number'
                }
              }
            }

            if (field) {
              field.data = val

              // if (key1 === 'productionOfficerNumber' && (val === '' || val === null)) {
              //   const cField = _.find(machineRec.qrCodeDataFields, { key: 'checkerNumber' });
              //   if (cField) {
              //     cField.field = 'Voucher Number'
              //   }
              // }
            }
          })
        }

        // console.log(_.flatMap(machineRec, (d) => console.log(d)))
      }
    },
    updateStatusHandler(data) {
      const machineRec = _.find(this.machineList, { serialNumber: data.serialNumber })
      if (machineRec) {
        machineRec.isConnected = data.isConnected
        machineRec.ipAddress = data.ipAddress
      }
    },
    loadMachineList(cb) {
      this.machineList = []

      const m2list = _.find(machineListCache, { locationId: this.selectedLocation.id })
      console.log(machineListCache)
      console.log(m2list)

      if (!m2list) {
        machineApi.findByLocationId(this.selectedLocation.id)
          .then((res) => {
            console.log(res)
            if (res.status === 200 && res.data) {
              machineListCache.push({
                locationId: res.data.locationId,
                locationName: res.data.locationName,
                machines: _.cloneDeep(res.data.machines),
              })

              this.addM(res.data.machines)
            }
          })
          .finally(() => cb())
      } else {
        this.addM(_.cloneDeep(m2list.machines))
        cb()
      }
      // if (machineList2) {
      //   this.machineList = []

      //   const m2list = _.find(machineList2.locations, { locationId: this.selectedLocation.id })
      //   if (m2list) {
      //     _.each(m2list.machineList, (val) => {
      //       this.machineList.push(val)
      //     })
      //   }
      // }
    },

    addM(machines) {
      _.each(machines, (val) => {
        if (val.isActive) {
          this.machineList.push({
            serialNumber: val.serialNumber,
            sequenceNumber: 0,
            sequenceConfigRevision: 0,
            transactionTime: null,
            transactionTime2: null,
            transactionWeight: 0,
            qrCodeData: null,
            qrCodeDataFields: [],
            isConnected: false,
            ipAddress: val.ipAddress,
            isCartonPackingMachine: val.isCartonPackingMachine,
          })
        }
      })
    },

    enableSubscription() {
      console.log('about to enable subscription')

      if (this.dsSequenceRecords.length > 0) {
        _.each(this.dsSequenceRecords, (record) => {
          record.discard()
          console.log(`Sequence subscription discarded for '${record.name}'`)
        })
        this.dsSequenceRecords = []
      }

      if (this.dsStatusRecords.length > 0) {
        _.each(this.dsStatusRecords, (record) => {
          record.discard()
          console.log(`Status subscription discarded for '${record.name}'`)
        })
        this.dsStatusRecords = []
      }

      if (this.machineList && this.machineList.length > 0) {
        _.each(this.machineList, (val) => {
          const record = ds.record.getRecord(`sequence/machine_${val.serialNumber}`)
          record.whenReady(() => {
            this.dsSequenceRecords.push(record)

            record.subscribe((data) => {
              this.updateHandler(data)
            }, true)
            console.log(`Sequence subscription enable for '${record.name}'`)
          })
        })

        _.each(this.machineList, (val) => {
          const record = ds.record.getRecord(`status/machine_${val.serialNumber}`)
          record.whenReady(() => {
            this.dsStatusRecords.push(record)

            record.subscribe((data) => {
              this.updateStatusHandler(data)
            }, true)
            console.log(`Status subscription enable for '${record.name}'`)
          })
        })
      }
    },
  },
};
</script>

<style>
</style>
